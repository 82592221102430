
import ContractsAttachmentsService from "@/modules/contracts-attachments/contracts-attachments-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            ContractsAttachmentsService.create(payload).then(response => {
                resolve({
                    message: "ContractsAttachments criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar ContractsAttachments",
                    success: false,
                    error
                });
            })
        })
    },
    update(id,payload) {
        return new Promise((resolve, reject) => {
            ContractsAttachmentsService.update(id,payload).then(response => {
                resolve({
                    message: "ContractsAttachments atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar ContractsAttachments",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            ContractsAttachmentsService.remove(id).then(response => {
                resolve({
                    message: "ContractsAttachments removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover ContractsAttachments",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            ContractsAttachmentsService.delete(id).then(response => {
                resolve({
                    message: "ContractsAttachments deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar ContractsAttachments",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            ContractsAttachmentsService.get(id).then(response => {
                resolve({
                    message: "ContractsAttachments encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar ContractsAttachments",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            ContractsAttachmentsService.paginate(query).then(response => {
                resolve({
                    message: "ContractsAttachments encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar ContractsAttachments",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            ContractsAttachmentsService.all().then(response => {
                resolve({
                    message: "ContractsAttachments encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar ContractsAttachments",
                    success: false,
                    error
                });
            })
        })
    }
}
